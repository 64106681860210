.contact-us-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  text-align: center;
}

.outlet, .outletShift {
  flex: 0 1 calc(33.33% - 20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  border: 2px solid transparent; /* Add border */
}

.outlet {
  background-color: #69a4c9;
}

.outletShift {
  background-color: #b09e37;
  color: rgb(0, 0, 0);
}

.outletShift h2 {
  color: rgb(0, 0, 0);
}

.black {
  color: black;
}

.outlet h2 {
  font-family: 'Libre Baskerville', serif;
  margin-bottom: 10px;
  color: rgb(131, 66, 6);
}

.outlet p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: rgb(131, 66, 6);
}

@media (max-width: 767px) {
  .outlet {
    flex: 0 1 calc(50% - 20px);
  }
}

body {
  background-color: #000000;
}

.closed {
  position: relative;
  border: 2px solid red;
}

.closed-banner {
  position: absolute;
  top: -15px; /* Adjust the position to overlap with the previous container */
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: white;
  padding: 5px;
  z-index: 1;
}
